import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import Alert from 'react-s-alert'

import { authLogin } from './actions/authActions';
import { displayErrorMessage } from './actions/formActions'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import packageJson from '../package.json'

import IndicatorsComponent from './components/indicators/'
import IndicatorsOSAComponent from './components/indicators OSA/'
import IndicatorsFoundrateComponent from './components/indicators foundrate/'
import IndicatorsVPValComponent from './components/indicators VPVal/'
import TMIndicatorsComponent from './components/indicatorsTM/'
import MonthlyColumns from './components/monthlyColumns'
import WeeklyColumns from './components/weeklyColumns'
import LastUpdateForReseller from './components/lastUpdateForReseller'
import VariationsMonthly from './components/variations_monthly'
import PXQPanel from './components/pxq'
import TMSummaryPanel from './components/tm_summary'
import InstockMonthComponent from './components/instockMonth'
import StatusPanel from './components/statusPanel';
//components with favs
import MonthlyColumnsNewVersion from './components/monthlyColumnsNewVersion'
import WeeklyColumnsNewVersion from './components/weeklyColumnsNewVersion'
import MonthlyColumnsDEV from './components/monthlyColumnsDEV'
import WeeklyColumnsDEV from './components/weeklyColumnsDEV'
import PXQDEVPanel from './components/pxqDEV'
import IndicatorsTMDEVComponent from './components/indicatorsTMDEV'
import VariationsDaily from './components/variations_daily'
import VariationsWeekly from './components/variations_weekly'
import PricesAnalisis from './components/prices_analisis'
import MarginPanel from './components/margins'
import InventoryPanel from './components/inventory'
import tasksDaySummaryPanel from './components/tasksDaySummary'
import SellingTrend from './components/sellingTrend'
import StockWithNoSales from './components/inventoryWithNoSales'
import OosPanel from './components/oosPanel'
import OosPanelBeta from './components/oosPanelBeta'
import CatalogAssortment from './components/catalogAssortment'
import CatalogAnomalies from './components/catalogAnomalies'
import SummaryPanel from './components/summaryPanel'
import sellThroughSummary from './components/sellThroughSummary'
import OperationalSummary from './components/operationalSummary'
import ManagementSummary from './components/managementSummary'
import CacheBuster from './components/common/cacheBuster'
import tmPerformedAllActionsList from './components/tmPerformedAllActionsList'
import tm2PerformedAllActionsList from './components/tm2PerformedAllActionsList'
import IndicatorsMultiDimsComponent from './components/indicatorsMultiDims'
import DremioOOSPanel from './components/oosDremio'

import SalesComponent from './components/smu_sales'
import StockComponent from './components/smu_inventory'
import FillrateComponent from './components/smu_fillrate'
import ProductsStoreComponent from './components/smu_products_store'
import Task2ControlPanel from './components/task2_control_panel'
import Taskmanager2Monitor from './components/taskmanager2_monitor'
import ticketFollowUp from './components/ticket_follow_up'

//import CampaignsSummary from './components/campaignsSummary'

import './index.css'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

library.add(fas, far)

//const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL

class App extends Component {
  constructor() {
    super()
    this.state = {
      version: packageJson.version || 'ERROR',
      containerType: 'container',
    }
  }
  componentDidMount() {
    console.log('Versión: ' + this.state.version)
    let windowSearch = window.location.search
    if (windowSearch.search(/container-fluid=1/i) >= 0 || windowSearch.search(/container-fluid=true/i) >= 0) {
      this.setState({ containerType: 'container-fluid' })
    }
  }

  render() {
    return (
      <div>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              return (
                <div className="dr-page">
                  <div className="dr-alert dr-alert-warning" role="alert" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); refreshCacheAndReload(); }}>
                    Estás usando una versión antigua! Haz click <a className="alert-link"><b>aquí</b></a> para actualizar a la última versión!
                  </div>
                </div>
              )
            }

            return (
              <div>
                {/*console.log("Bundle version - ", global.appVersion)*/}
              </div>
            );
          }}
        </CacheBuster>

        <Switch>

          <Route path='/' >
            <div className={this.state.containerType}>
              <div>
                <Route exact path='/indicators' component={IndicatorsComponent} />
                <Route exact path='/indicators-osa' component={IndicatorsOSAComponent} />
                <Route exact path='/indicators-foundrate' component={IndicatorsFoundrateComponent} />
                <Route exact path='/indicators-vp-val' component={IndicatorsVPValComponent} />
                <Route exact path='/indicators-multi' component={IndicatorsMultiDimsComponent} />
                <Route exact path='/indicators-with-total' render={(props) => <IndicatorsComponent {...props} withTotalTable={true} />} />
                <Route exact path='/tm-indicators' component={TMIndicatorsComponent} />
                <Route exact path='/monthly-columns' component={MonthlyColumns} />
                <Route exact path='/selling-trend' component={SellingTrend} />
                <Route exact path='/weekly-columns' component={WeeklyColumns} />
                <Route exact path='/pxq' component={PXQPanel} />
                <Route exact path='/tm-summary' component={TMSummaryPanel} />
                <Route exact path='/instock-month' component={InstockMonthComponent} />

                <Route exact path='/last-update-reseller' render={(props) => <LastUpdateForReseller {...props} spObjective={'reseller'} />} />
                <Route exact path='/last-update-datamind' render={(props) => <LastUpdateForReseller {...props} spObjective={'datamind'} />} />
                <Route exact path='/last-update-manufacturer' render={
                  (props) => <LastUpdateForReseller {...props} spObjective={'manufacturer'} externalConf={{ domLayout: 'autoHeight', height: null }} />}
                />
                <Route exact path='/status-monitor' component={StatusPanel} />

                { /* DEV FAVS */}
                <Route exact path='/dev/monthly-columns' component={MonthlyColumnsDEV} />
                <Route exact path='/monthly-columns-new-version' component={MonthlyColumnsNewVersion} />
                <Route exact path='/dev/weekly-columns' component={WeeklyColumnsDEV} />
                <Route exact path='/weekly-columns-new-version' component={WeeklyColumnsNewVersion} />
                <Route exact path='/dev/pxq' component={PXQDEVPanel} />
                <Route exact path='/dev/tm-indicators' component={IndicatorsTMDEVComponent} />
                <Route exact path='/variations-monthly' component={VariationsMonthly} />
                <Route exact path='/variations-weekly' component={VariationsWeekly} />
                <Route exact path='/variations-daily' component={VariationsDaily} />
                <Route exact path='/prices' component={PricesAnalisis} />
                <Route exact path='/margins' component={MarginPanel} />
                <Route exact path='/inventory' component={InventoryPanel} />
                <Route exact path='/stock-with-no-sales' component={StockWithNoSales} />
                <Route exact path='/oos' component={OosPanel} />
                <Route exact path='/oos-beta' component={OosPanelBeta} />
                <Route exact path='/catalog-assortment' component={CatalogAssortment} />
                <Route exact path='/catalog-anomalies' component={CatalogAnomalies} />
                <Route exact path='/summary-panel' component={SummaryPanel} />

                <Route exact path='/sell-through-summary' component={sellThroughSummary} />
                <Route exact path='/tasks-day-summary' component={tasksDaySummaryPanel} />
                <Route exact path='/operational-summary' component={OperationalSummary} />
                <Route exact path='/management-summary' component={ManagementSummary} />
                <Route exact path='/tasks-performed-all-actions' component={tmPerformedAllActionsList} />
                <Route exact path='/tasks2-performed-all-actions' component={tm2PerformedAllActionsList} />
                {/** DREMIO PANELS */}
                <Route exact path='/dre/oos' component={DremioOOSPanel} />
    
                {/* smu*/}
                <Route exact path='/sales' component={SalesComponent} />
                <Route exact path='/stock' component={StockComponent} />
                <Route exact path='/products_store' component={ProductsStoreComponent} />
                <Route exact path='/fillrate' component={FillrateComponent} />

                {/* Task 2.0*/}
                <Route exact path='/ticket_follow_up' component={ticketFollowUp} />
                <Route exact path='/task2_control_panel' component={Task2ControlPanel} />
                <Route exact path='/taskmanager2_monitor' component={Taskmanager2Monitor} />
              </div>
              <Alert position='bottom' html />
            </div>
          </Route>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userProfile.isAuthenticated,
    appIsLoading: state.appStatus.isLoading,
    requestRejected: state.appStatus.requestError
  }
};

const mapDispatchToProps = {
  authLogin,
  displayErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(App);